<template lang="pug">
div
  van-address-list(v-model='chosenAddressId', 
    :list='list', 
    default-tag-text='默认', 
    @add='onAdd', 
    @edit='onEdit')
  van-action-sheet(v-model='showEdit', title='编辑联系地址')
    van-address-edit(:area-list='areaList',
      :address-info= 'editingAddress',
      :show-delete= 'isEdit', 
      show-set-default='', 
      show-search-result='', 
      :search-result='searchResult', 
      :area-columns-placeholder="['请选择', '请选择', '请选择']", 
      @save='onSave', 
      @delete='onDelete', 
      @change-detail='onChangeDetail')

</template>

<script>
import { Sticky } from 'vant'

export default {
  components: {
    [Sticky.name]: Sticky
  },
  data() {
    return {
      chosenAddressId: '1',
      editingAddress: {},
      showEdit: false,
      isEdit: false,
      areaList: {
        province_list: {
          110000: '北京市',
          120000: '天津市'
        },
        city_list: {
          110100: '北京市',
          110200: '县',
          120100: '天津市',
          120200: '县'
        },
        county_list: {
          110101: '东城区',
          110102: '西城区',
          110105: '朝阳区',
          110106: '丰台区',
          120101: '和平区',
          120102: '河东区',
          120103: '河西区',
          120104: '南开区',
          120105: '河北区',
          // ....
        }
      },
      searchResult: [],
      list: [
        {
          id: '1',
          name: '张三',
          tel: '13000000000',
          address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
          province: '',       // 省份
          city: '',           // 城市
          county: '',         // 区县
          addressDetail: '',  // 详细地址
          areaCode: '',   // 地区编码，通过省市区选择获取（必填）
          postalCode: '', // 邮编
          isDefault: false  
        },
        {
          id: '2',
          name: '李四',
          tel: '1310000000',
          address: '浙江省杭州市拱墅区莫干山路 50 号',
          isDefault: false
        },
      ]
    }
  },
  userId() {
    return this.$store.state.userInfo.user_poi_users
  },
  methods: {
    async onLoad() {
      this.list = []
      try {
        let res = await this.$api.GetContactAddress(this.customer_id)
        this.list = res.data.items.map((item) => {
         return {
            id: item.id,
            name: item.receipt,
            tel: item.tel,
            address: item.full_address,
            isDefault: item.is_default  === 'FALSE' ? false : true,
            province: item.province_poi_province,        
            city: item.city_poi_city,        
            county: item.district_poi_district,      
            addressDetail: item.detail_address,
            areaCode: '',   
            postalCode: '', 
          }
        })
        // console.log('获取我的客户地址',this.list)
      } catch (ex) {
          // console.log(`我的客户地址请求失败:${ex}`)
      }
    },
    onAdd() {
      this.editingAddress = { id: this.list.length }
      this.isEdit = false
      this.showEdit = true
      // console.log('onadd',this.editingAddress)
      this.$toast('新增地址')
    },
    onEdit(item, index) {
      this.isEdit = true;
      this.showEdit = true;
      this.editingAddress = item;
      // console.log('edit',item)
      this.$toast('编辑地址:' + index);
    },
   async onSave(info) {
      this.showEdit = false
      let param = {
          receipt: info.name,
          tel: info.tel,
          province_poi_province: 1,//info.province,
          city_poi_city: 1, //info.city,
          district_poi_district: 1,//info.county,
          full_address: '北京市北京市东城区'+info.addressDetail,
          seller_poi_users: this.userId, // 'cookie查找' 1626写死测试,
          detail_address: info.addressDetail, 
          is_default: info.isDefault ? '1' : '0',
          cus_com_poi_ofc_cus_companys: this.customer_id
      }
      // put 
      if (this.isEdit) {
        // console.log('put',param)
        param.id = info.id
        try {
          let data = JSON.stringify(param); 
          let res = await this.$api.PutContactAddress(data)
          if(info.isDefault){
            this.list.forEach((item) => {
              if(item.id != info.id){
                item.isDefault = false
              }
            })
          }
        } catch (error) {
          this.$toast('修改失败' + error)
        }
        this.list = this.list.map((item) =>
          item.id === info.id ? info : item
        );
      } else { // post 
        // console.log('post',param)
        param.type = 'new'
       
        try {
          let data = JSON.stringify(param); 
          let res = await this.$api.PostContactAddress(data)
          info.id = res.data.id
          this.list.push(info)
          if(info.isDefault){
            this.list.forEach((item) => {
              if(item.id != info.id){
                item.isDefault = false
              }
            })
          }
          this.$toast('添加成功',res)
        } catch (error) {
          this.$toast('添加失败' + error)
        }
      }
      this.chosenAddressId = info.id;
      // console.log('save',info)
    },
    async onDelete(info) {
      this.showEdit = false
      
      try {
        let param = { id: info.id }
        let res = await this.$api.DeleteContactAddress(param)
        this.list = this.list.filter((item) => item.id !== info.id)
        this.$toast('删除成功')
      } catch (error) {
        this.$toast('删除失败' + error)
      }
     
      if (this.chosenAddressId === info.id) {
        this.chosenAddressId = null;
      }
    },
    onChangeDetail(val) {
      if (val) {
        this.searchResult = [
          {
            name: '黄龙万科中心',
            address: '杭州市西湖区',
          },
        ];
      } else {
        this.searchResult = [];
      }
    },
  },
  async activated() {
    this.customer_id = (this.$route.query).id || 2531
    if(this.customer_id === 0) this.$toast('Error:请检查用户登录信息')
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      rightText: '新增地址',
      rightArrow: true,
      onClickRight: ()=> {
        this.onAdd()
     }
    }),
    this.onLoad()
  }
}
</script>